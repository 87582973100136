// import store from '@/state/store'

export default [{
    path: '/',
    meta: {
        authRequired: false
    },
    name: 'home',
    component: () => import('@/views/Home.vue'),
},
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: () => import('@/views/Login.vue'),
    //     meta: {
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             // if (store.getters['auth/loggedIn'])
    //             if (false) {
    //                 // Redirect to the home page instead
    //                 next({
    //                     name: 'home'
    //                 })
    //             } else {
    //                 // Continue to the login page
    //                 next()
    //             }
    //         },
    //     },
    // },
    // {
    //     path: '/dashboard',
    //     name: 'Dashboard',
    //     component: () => import('./views/calendar/index'),
    //     meta: {
    //         authRequired: false,
    //     },
    // },
]